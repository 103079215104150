import Link from "next/link";
import styles from "@styles/components/elements/cta-button.module.scss";

export interface props {
  link?: string;
  text: string;
  BGcolor?: string | boolean;
  hoverColor?: string | boolean;
  textColor?: string | boolean;
  isInverse?: boolean;
  showArrow?: boolean;
  autoShip?: boolean;
  overrideMargin?: boolean;
  className?: string;
}

export default function CtaButton({
  link,
  text = "",
  BGcolor = false,
  hoverColor = false,
  textColor = false,
  isInverse = false,
  showArrow = true,
  autoShip = false,
  overrideMargin = false,
  className = "",
}: props) {
  const style: any = {};
  const hoverStyle: any = {};

  if (BGcolor) {
    style.backgroundColor = BGcolor;
  }
  if (textColor) {
    style.color = textColor;
  }
  if (hoverColor) {
    hoverStyle.backgroundColor = hoverColor;
  }

  const mouseHoverAction = (e: any, hovering: boolean) => {
    e.currentTarget.style.backgroundColor = hovering ? hoverColor : BGcolor;
  };

  if (!link) {
    return (
      <button
        className={`${className} ${styles.ctaBtn} ${
          isInverse ? styles.inverse : ""
        } ${overrideMargin ? "" : styles.defaultMargin} btn`}
        style={style}
        onMouseEnter={(e) => mouseHoverAction(e, true)}
        onMouseLeave={(e) => mouseHoverAction(e, false)}
      >
        {text}
        {showArrow && <span className="icon icon-arrow-right"></span>}
        {autoShip && <span className="fas fa-sync-alt"></span>}
      </button>
    );
  }

  return (
    <Link
      className={`${className} ${styles.ctaBtn} ${
        isInverse ? styles.inverse : ""
      } ${overrideMargin ? "" : styles.defaultMargin} btn`}
      href={link}
      title={text}
      style={style}
      onMouseEnter={(e) => mouseHoverAction(e, true)}
      onMouseLeave={(e) => mouseHoverAction(e, false)}
    >
      {text}
      {showArrow && <span className="icon icon-arrow-right"></span>}
    </Link>
  );
}
